import moment, { Moment } from 'moment-timezone';
import { BreakType, DeliveryBlockRecharge } from '@bringg/types';

import { DeliveryBlockBreakBase } from 'bringg-web/features/delivery-blocks-v2/new-modal/breaks/break-types';
import { DeliveryBlockRechargeBase } from 'bringg-web/features/delivery-blocks-v2/new-modal/recharge/recharge-types';

const rechargeValidator = () => {
	const isTimeInRange = (rechargeTime: Moment, start: Moment, end: Moment, inclusivity?: '()' | '[)' | '(]' | '[]') =>
		rechargeTime.isBetween(start, end, undefined, inclusivity);
	const isTimeInDeliveryBlockRange = (rechargeTime: Moment, deliveryBlockTimes, rechargeDuration: number) => {
		const startOfValidRechargeTime = moment(deliveryBlockTimes.startTime);
		const endOfValidRechargeTime = moment(deliveryBlockTimes.endTime).subtract(rechargeDuration, 'minutes');

		return isTimeInRange(rechargeTime, startOfValidRechargeTime, endOfValidRechargeTime, '[]');
	};

	const isTimeOverlapBreaks = (
		rechargeTime: Moment,
		deliveryBlockBreaks: DeliveryBlockBreakBase[],
		timezone: string
	): { result: boolean; breakType?: BreakType } => {
		const breakType = getBreakTypeIfTimeOverlapBreaks(rechargeTime, deliveryBlockBreaks, timezone);
		return { result: breakType != null, breakType };
	};

	const getBreakTypeIfTimeOverlapBreaks = (
		rechargeTime: Moment,
		deliveryBlockBreaks: DeliveryBlockBreakBase[],
		timezone: string
	) => {
		const aBreak = deliveryBlockBreaks.find((breakItem: DeliveryBlockBreakBase) => {
			const startTime = moment(breakItem.start_time).tz(timezone);
			const endTime = moment(breakItem.end_time).tz(timezone);
			return isTimeInRange(rechargeTime, startTime, endTime, '[)');
		});
		return aBreak?.break_type;
	};

	const isRechargeTimeAlreadyExists = (
		rechargeItem: Moment,
		deliveryBlockRecharges,
		rechargeDuration: number,
		timezone: string
	) => {
		const duplication = deliveryBlockRecharges.filter(item => {
			const startTime = moment(item.start_time).tz(timezone);
			const endTime = moment(item.start_time).tz(timezone).add(rechargeDuration, 'minutes');
			return isTimeInRange(rechargeItem, startTime, endTime, '[]');
		});
		return duplication.length > 1;
	};

	const isRechargeTimeValid = (
		rechargeItem: DeliveryBlockRecharge,
		deliveryBlockTimes,
		rechargeDuration: number,
		deliveryBlockRecharges: DeliveryBlockRechargeBase[],
		deliveryBlockBreaks: DeliveryBlockBreakBase[],
		timezone: string
	) => {
		const startTimeValid = isRechargeStartTimeValid(
			moment(rechargeItem.start_time).tz(timezone),
			deliveryBlockTimes,
			rechargeDuration,
			deliveryBlockRecharges,
			deliveryBlockBreaks,
			timezone
		);
		if (!startTimeValid.result) {
			return startTimeValid;
		}
		const rechargeEndTime = moment(rechargeItem.start_time).add(rechargeDuration, 'minutes').tz(timezone);
		const endTimeValid = isRechargeEndTimeValid(
			rechargeEndTime,
			deliveryBlockTimes,
			rechargeDuration,
			deliveryBlockRecharges,
			deliveryBlockBreaks,
			timezone
		);
		if (!endTimeValid.result) {
			return endTimeValid;
		}
		return { result: true };
	};

	const isRechargeStartTimeValid = (
		rechargeTime: Moment,
		deliveryBlockTimes,
		rechargeDuration: number,
		deliveryBlockRecharges: DeliveryBlockRechargeBase[],
		deliveryBlockBreaks: DeliveryBlockBreakBase[],
		timezone: string
	) => {
		if (!isTimeInDeliveryBlockRange(rechargeTime, deliveryBlockTimes, rechargeDuration)) {
			return {
				result: false,
				errorMsg: 'DELIVERY_BLOCK_MODAL.RELOAD.START_TIME_IS_OUT_OF_BLOCK_RANGE'
			};
		}
		const isTimeOverlapBreaksResult = isTimeOverlapBreaks(rechargeTime, deliveryBlockBreaks, timezone);
		if (isTimeOverlapBreaksResult.result) {
			const breakType = isTimeOverlapBreaksResult.breakType;
			return {
				result: false,
				errorMsg:
					breakType === BreakType.Fixed
						? 'DELIVERY_BLOCK_MODAL.RELOAD.START_TIME_OVERLAP_BREAK_RANGE'
						: 'DELIVERY_BLOCK_MODAL.RELOAD.START_TIME_OVERLAP_FLEX_BREAK_RANGE'
			};
		}
		if (isRechargeTimeAlreadyExists(rechargeTime, deliveryBlockRecharges, rechargeDuration, timezone)) {
			return {
				result: false,
				errorMsg: 'DELIVERY_BLOCK_MODAL.RELOAD.START_TIME_ALREADY_EXISTS'
			};
		}
		return {
			result: true
		};
	};

	const isRechargeEndTimeValid = (
		rechargeTime: Moment,
		deliveryBlockTimes,
		rechargeDuration: number,
		deliveryBlockRecharges: DeliveryBlockRechargeBase[],
		deliveryBlockBreaks: DeliveryBlockBreakBase[],
		timezone: string
	) => {
		if (!isTimeInDeliveryBlockRange(rechargeTime, deliveryBlockTimes, rechargeDuration)) {
			return {
				result: false,
				errorMsg: 'DELIVERY_BLOCK_MODAL.RELOAD.END_TIME_IS_OUT_OF_BLOCK_RANGE'
			};
		}
		const isTimeOverlapBreaksResult = isTimeOverlapBreaks(rechargeTime, deliveryBlockBreaks, timezone);
		if (isTimeOverlapBreaksResult.result) {
			const breakType = isTimeOverlapBreaksResult.breakType;
			return {
				result: false,
				errorMsg:
					breakType === BreakType.Fixed
						? 'DELIVERY_BLOCK_MODAL.RELOAD.END_TIME_OVERLAP_BREAK_RANGE'
						: 'DELIVERY_BLOCK_MODAL.RELOAD.END_TIME_OVERLAP_FLEX_BREAK_RANGE'
			};
		}
		if (isRechargeTimeAlreadyExists(rechargeTime, deliveryBlockRecharges, rechargeDuration, timezone)) {
			return {
				result: false,
				errorMsg: 'DELIVERY_BLOCK_MODAL.RELOAD.END_TIME_ALREADY_EXISTS'
			};
		}
		return {
			result: true
		};
	};

	return { isRechargeTimeValid };
};

export default rechargeValidator;
