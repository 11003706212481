import { CreateSpeedFactor, SpeedFactor } from '@bringg/dashboard-sdk/dist/SpeedFactor/Service/SpeedFactor.service';
import { EndOfWeekDay } from '@bringg/types';
import { getDaysBetweenDates } from '@bringg-frontend/utils';
import moment, { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';
import _orderBy from 'lodash/orderBy';

import TimezoneService from 'bringg-web/services/timezone/timezone-service';

// Service Area Speed Factors CRUD Schema Examples and notes: https://bringg.atlassian.net/wiki/spaces/~727657759/pages/3400237068/Service+Area+Speed+Data+CRUD+Schema+Examples
//
const CURRENT_YEAR = new Date().getFullYear();
const DATE_FORMAT = 'YYYY-MM-DD';

export type ActionType = 'current' | 'current_and_following' | 'all';

export type CalendarEvent = {
	id: string;
	start: string;
	end: string;
	title: string;
	originalEnd: string; // delete at the end
	originalStart: string; // delete at the end
	originalEvent: SpeedFactor;
};

export type EventFormData = {
	factor: number;
	timeFrame: 'specificHours' | 'allDay';
	service_area_id?: number;
	applicableDays?: EndOfWeekDay[];
	effectiveDates?: [Moment, Moment];
	intervals?: [Moment, Moment];
};

export const buildCreateSpeedFactorPayload = (formData: EventFormData, timezone?: string): CreateSpeedFactor[] => {
	const [start_day, start_month, end_day, end_month] = getDayAndMonthNumbers(formData.effectiveDates);

	const intervals = getIntervals(formData, timezone);

	// In case when start and end of the range in different years
	// split range into two payloads
	if (start_month > end_month) {
		return [
			{
				service_area_id: formData.service_area_id,
				factor: formatFactorForDb(formData.factor),
				start_date_day: start_day,
				start_date_month: start_month,
				end_date_day: 31,
				end_date_month: 12,
				days_of_week: daysFromEndOfWeekDayToIsoWeekday(formData.applicableDays),
				intervals: intervals,
				original_uuid: uuidv4()
			},
			{
				service_area_id: formData.service_area_id,
				factor: formatFactorForDb(formData.factor),
				start_date_day: 1,
				start_date_month: 1,
				end_date_day: end_day,
				end_date_month: end_month,
				days_of_week: daysFromEndOfWeekDayToIsoWeekday(formData.applicableDays),
				intervals: intervals,
				original_uuid: uuidv4()
			}
		];
	}

	return [
		{
			service_area_id: formData.service_area_id,
			factor: formatFactorForDb(formData.factor),
			start_date_day: start_day,
			start_date_month: start_month,
			end_date_day: end_day,
			end_date_month: end_month,
			days_of_week: daysFromEndOfWeekDayToIsoWeekday(formData.applicableDays),
			intervals: intervals,
			original_uuid: uuidv4()
		}
	];
};

export const buildSpeedFactorsFromPayloads = payloadsArray => {
	let speedFactors = [];

	payloadsArray.forEach(payload => {
		const newSpeedFactors = payload.days_of_week.map(day_of_week => {
			return {
				id: null,
				service_area_id: payload.service_area_id,
				merchant_id: 0,
				merchant_uuid: '',
				start_date: `1972-${payload.start_date_month}-${payload.start_date_day}`,
				end_date: `1972-${payload.end_date_month}-${payload.end_date_day}`,
				factor: payload.factor,
				day_of_week: day_of_week,
				interval_start: payload.intervals[0][0],
				interval_end: payload.intervals[0][1],
				original_uuid: '',
				created_at: '',
				updated_at: ''
			};
		});

		speedFactors = [...speedFactors, ...newSpeedFactors];
	});

	return speedFactors;
};

export const normalizeEvents = (
	speedFactors: SpeedFactor[],
	yearsCount = 1,
	fromYear = null,
	withPreviousYear = false,
	timezone = 'UTC'
) => {
	const normalizedEvents = [];
	const years = [];
	const startYear = Number(fromYear) || CURRENT_YEAR;

	// Generate data for previous year, current year + yearsCount more
	const start = withPreviousYear ? -1 : 0;
	for (let i = start; i < yearsCount; i++) {
		years.push(startYear + i);
	}

	years.forEach(year => {
		speedFactors.map(event => {
			const [startDate, endDate] = getDatesForCurrentYearFrom1972(event.start_date, event.end_date, year);

			const daysBetween = getDaysBetweenDates(startDate.toDate(), endDate.toDate(), 'UTC');

			Object.keys(daysBetween).forEach(dayBetween => {
				const weekday = moment(dayBetween).day() + 1;

				if (weekday !== event.day_of_week) return;

				const isDST = moment(dayBetween).isDST();
				const DSTOffsetMinutes = TimezoneService.getDST(timezone);

				const startDateTime = moment
					.utc(`${moment(dayBetween).format(DATE_FORMAT)}T${event.interval_start}`, 'YYYY-MM-DD H')
					.subtract(isDST ? DSTOffsetMinutes : 0, 'minutes')
					.toDate();
				const endDateTime = moment
					.utc(`${moment(dayBetween).format(DATE_FORMAT)}T${event.interval_end - 1}:59`, 'YYYY-MM-DD H:mm')
					.subtract(isDST ? DSTOffsetMinutes : 0, 'minutes')
					.toDate();

				const factor = ((event.factor - 1) * 100).toFixed();

				normalizedEvents.push({
					id: event.id,
					title: `+${factor}%`,
					start: startDateTime,
					end: endDateTime,
					factor,
					originalStart: startDate.toDate(),
					originalEnd: endDate.toDate(),
					originalEvent: event
				});
			});
		});
	});

	return normalizedEvents;
};

const getDayAndMonthNumbers = (dates: [Moment, Moment]) => {
	return [dates[0].date(), dates[0].month() + 1, dates[1].date(), dates[1].month() + 1];
};

const getDatesForCurrentYearFrom1972 = (startDate, endDate, toYear = CURRENT_YEAR) => {
	const currentYear = toYear.toString();

	const currentStartDate = moment.utc(startDate.replace('1972', currentYear), DATE_FORMAT);
	const currentEndDate = moment.utc(endDate.replace('1972', currentYear), DATE_FORMAT);

	if (currentStartDate > currentEndDate) {
		// eslint-disable-next-line no-console
		console.warn('Start date is later then end date!', currentStartDate, currentEndDate);
	}

	return [currentStartDate, currentEndDate];
};

const getIntervals = (formData: EventFormData, timezone = 'UTC'): [number, number][] => {
	if (formData.timeFrame == 'allDay') {
		return [[0, 24]];
	} else if (formData.timeFrame == 'specificHours') {
		// Checking if DST is enabled for chosen timezone
		// This function used in different validations where TZ isn't provided thus taken UTC
		const isDST = moment().tz(timezone).isDST();
		const startHour = moment(formData.intervals[0]).utc().hour();

		const endMinute = moment(formData.intervals[1]).utc().minute();
		let endHour = moment(formData.intervals[1]).utc().hour();

		// When intervals set via form in EventDialog values will be: 00:00 and 23:00
		// When intervals set via DnD in TrafficCalendar values will be: 00:00 and 23:59
		// So the latter should be converted to the number: 24 instead of 23
		if (endMinute === 59) endHour++;

		// If now is Daylight Saving Time on chosen timezone we need to ignore DST offset upon saving thus adding 1h to intervals
		return [[startHour + Number(isDST), endHour + Number(isDST)]];
	} else {
		throw new Error('There is incorrect timeFrame on in form!');
	}
};

// ISO weekdays: 1 - Monday, 7 - Sunday
// End Of Weekday: Friday is 0, Saturday is 1, through to 6 for Thursday
export const isoWeekdayToEndOfWeekday = (weekday: number) => {
	const map = { 1: 3, 2: 4, 3: 5, 4: 6, 5: 0, 6: 1, 7: 2 };

	return map[weekday];
};

// SpeedFactor weekdays: 1 - 7 meaning Sunday - Saturday
// End Of Weekday: Friday is 0, Saturday is 1, through to 6 for Thursday
const endOfWeekdayToSpeedfactorWeekday = (weekday: EndOfWeekDay) => {
	const map = { 0: 6, 1: 7, 2: 1, 3: 2, 4: 3, 5: 4, 6: 5 };

	return map[weekday];
};

const daysFromEndOfWeekDayToIsoWeekday = (dates: EndOfWeekDay[]) => {
	return dates.map(date => endOfWeekdayToSpeedfactorWeekday(date));
};

export const buildCreateSpeedFactorsOnDelete = (
	calEvent: CalendarEvent,
	speedFactors: SpeedFactor[],
	action: ActionType
): CreateSpeedFactor[] => {
	if (action === 'all') return [];

	const ranges = [];

	const speedFactor: SpeedFactor = calEvent.originalEvent;
	const calEventYear = moment.utc(calEvent.start).year();

	const [dbStartDate, dbEndDate] = getDatesForCurrentYearFrom1972(
		speedFactor.start_date,
		speedFactor.end_date,
		calEventYear
	);

	const newEndDate = moment.utc(calEvent.start).subtract(1, 'day');
	if (newEndDate >= dbStartDate) {
		ranges.push([dbStartDate, newEndDate]);
	}

	// Do nothing for current_and_following
	if (action === 'current') {
		const newStartDate = moment.utc(calEvent.start).startOf('day').add(1, 'day');
		if (dbEndDate >= newStartDate) {
			ranges.push([newStartDate, dbEndDate]);
		}
	}

	// Filter events by original_uuid and then get unique days of week
	const daysOfWeek = Array.from(
		new Set(
			speedFactors
				.filter(event => event.original_uuid === speedFactor.original_uuid)
				.map(event => event.day_of_week)
		)
	);

	return ranges.map(range => {
		const [start_day, start_month, end_day, end_month] = getDayAndMonthNumbers(range);

		return {
			service_area_id: speedFactor.service_area_id,
			factor: speedFactor.factor,
			start_date_day: start_day,
			start_date_month: start_month,
			end_date_day: end_day,
			end_date_month: end_month,
			days_of_week: daysOfWeek,
			intervals: [[speedFactor.interval_start, speedFactor.interval_end]],
			original_uuid: uuidv4()
		};
	});
};

export const buildCreateSpeedFactorsOnOverride = (
	speedFactor: SpeedFactor,
	formData: EventFormData,
	calEvent?: CalendarEvent,
	action?: ActionType
): CreateSpeedFactor[] => {
	const ranges = [];

	const calEventYear = moment.utc(calEvent?.start).year();

	const calStartDate = moment.utc(formData.effectiveDates[0]);
	const calEndDate = moment.utc(formData.effectiveDates[1]);

	const [dbStartDate, dbEndDate] = getDatesForCurrentYearFrom1972(
		speedFactor.start_date,
		speedFactor.end_date,
		calEventYear
	);

	if (action === 'current') {
		const newEndDate = moment.utc(calEvent.start).subtract(1, 'day');
		if (newEndDate >= dbStartDate) {
			ranges.push([dbStartDate, newEndDate]);
		}

		const newStartDate = moment.utc(calEvent.start).startOf('day').add(1, 'day');
		if (dbEndDate >= newStartDate) {
			ranges.push([newStartDate, dbEndDate]);
		}
	} else if (action === 'current_and_following') {
		const newEndDate = moment.utc(calEvent.start).subtract(1, 'day');
		if (newEndDate >= dbStartDate) {
			ranges.push([dbStartDate, newEndDate]);
		}
	} else {
		if (calStartDate > dbStartDate) {
			ranges.push([dbStartDate, calStartDate.subtract(1, 'day')]);
		}

		if (dbEndDate > calEndDate) {
			ranges.push([calEndDate.add(1, 'day'), dbEndDate]);
		}
	}

	return ranges.map(range => {
		const [start_day, start_month, end_day, end_month] = getDayAndMonthNumbers(range);

		return {
			service_area_id: speedFactor.service_area_id,
			factor: speedFactor.factor,
			start_date_day: start_day,
			start_date_month: start_month,
			end_date_day: end_day,
			end_date_month: end_month,
			days_of_week: [speedFactor.day_of_week],
			intervals: [[speedFactor.interval_start, speedFactor.interval_end]],
			original_uuid: uuidv4()
		};
	});
};

export const buildCreateSpeedFactorsOnUpdate = (
	calEvent: CalendarEvent,
	speedFactors: SpeedFactor[],
	action: ActionType,
	formData: EventFormData,
	timezone?: string
): CreateSpeedFactor[] => {
	const ranges = [];

	const speedFactor: SpeedFactor = calEvent.originalEvent;
	const calEventYear = moment.utc(calEvent.start).year();
	const [dbStartDate, dbEndDate] = getDatesForCurrentYearFrom1972(
		speedFactor.start_date,
		speedFactor.end_date,
		calEventYear
	);
	// Get all days of week for newly created speedFactors
	// Filter events by original_uuid and then get unique days of week
	const allDaysOfWeek = Array.from(
		new Set(
			speedFactors
				.filter(event => event.original_uuid === speedFactor.original_uuid)
				.map(event => event.day_of_week)
		)
	);

	if (action === 'all') {
		ranges.push([dbStartDate, dbEndDate, allDaysOfWeek, formData]);
	} else if (action === 'current') {
		// Create new range Before
		const newEndDate = moment.utc(calEvent.start).subtract(1, 'day');
		if (newEndDate >= dbStartDate) {
			ranges.push([dbStartDate, newEndDate, allDaysOfWeek]);
		}

		// Create new range After
		const newStartDate = moment.utc(calEvent.start).startOf('day').add(1, 'day');
		if (dbEndDate >= newStartDate) {
			ranges.push([newStartDate, dbEndDate, allDaysOfWeek]);
		}

		// Create new range Current
		const currentStartDate = moment.utc(calEvent.start);
		const currentEndDate = moment.utc(calEvent.start);
		// Pass formData for current event
		// Pass only current day as days_of_weeks for current event
		ranges.push([currentStartDate, currentEndDate, [calEvent.originalEvent.day_of_week], formData]);
	} else if (action === 'current_and_following') {
		// Create new range Before
		const newEndDate = moment.utc(calEvent.start).subtract(1, 'day');
		if (newEndDate >= dbStartDate) {
			ranges.push([dbStartDate, newEndDate, allDaysOfWeek]);
		}

		// Create new range Current + Before
		const newStartDate = moment.utc(calEvent.start).startOf('day');
		if (dbEndDate >= newStartDate) {
			ranges.push([newStartDate, dbEndDate, allDaysOfWeek, formData]);
		}
	}

	return ranges.map(range => {
		const [start_day, start_month, end_day, end_month] = getDayAndMonthNumbers(range);

		const daysOfWeek = range[2];
		const formData = range[3];
		const factor = formData ? formatFactorForDb(formData.factor) : speedFactor.factor;
		const intervals = formData
			? getIntervals(formData, timezone)
			: [[speedFactor.interval_start, speedFactor.interval_end]];

		return {
			service_area_id: speedFactor.service_area_id,
			factor: factor,
			start_date_day: start_day,
			start_date_month: start_month,
			end_date_day: end_day,
			end_date_month: end_month,
			days_of_week: daysOfWeek,
			intervals: intervals,
			original_uuid: uuidv4()
		} as CreateSpeedFactor;
	});
};

export const validateOverlapping = (
	formData: EventFormData,
	speedFactors: SpeedFactor[],
	event?: CalendarEvent,
	action?: ActionType
): { valid: boolean; message: string; speedFactors?: SpeedFactor[] } => {
	let newSpeedFactors: SpeedFactor[];
	let mergedSpeedFactors: SpeedFactor[];

	if (event) {
		// updating
		newSpeedFactors = buildSpeedFactorsFromPayloads(
			buildCreateSpeedFactorsOnUpdate(event, speedFactors, action, formData)
		);

		const speedFactorsWithoutDeleted = speedFactors.filter(
			f => f.original_uuid !== event.originalEvent.original_uuid
		);

		mergedSpeedFactors = [...speedFactorsWithoutDeleted, ...newSpeedFactors];
	} else {
		// creating
		newSpeedFactors = buildSpeedFactorsFromPayloads(buildCreateSpeedFactorPayload(formData));
		mergedSpeedFactors = [...speedFactors, ...newSpeedFactors];
	}

	const overlaps = overlappedSpeedfactors(mergedSpeedFactors);
	if (overlaps.length > 0) {
		return {
			valid: false,
			message: 'Events overlapping!',
			speedFactors: overlaps
		};
	} else {
		return { valid: true, message: '' };
	}
};

export const overlappedSpeedfactors = (speedFactors: SpeedFactor[]): SpeedFactor[] => {
	if (speedFactors.length === 0) return [];

	const index = { months: {} };
	const overlaps = [];

	// Fake (new) speedFactors with id: null should be in the start of array so the overlapps will be accumulated correctly
	const sortedSpeedFactors = _orderBy(speedFactors, ['id'], ['desc']);

	for (const speedFactor of sortedSpeedFactors) {
		const dayOfWeek = speedFactor.day_of_week;

		const daysBetween = getDaysBetweenDates(
			moment.utc(speedFactor.start_date).toDate(),
			moment.utc(speedFactor.end_date).toDate(),
			'UTC'
		);

		// daysBetween = {
		// 'Fri Nov 03 1972 00:00:00': true,
		// 'Sat Nov 04 1972 00:00:00': true,
		// 'Sun Nov 05 1972 00:00:00': true,
		// ...
		// }

		for (const date of Object.keys(daysBetween)) {
			const month = new Date(date).getMonth() + 1;
			const day = new Date(date).getDate();

			// Find or create month
			if (!Object.hasOwn(index.months, month)) {
				index.months[month] = { days: {} };
			}

			// Find or create day for the month
			if (!Object.hasOwn(index.months[month].days, day)) {
				index.months[month].days[day] = { day_of_weeks: {} };
			}

			// Find or create day of week for the day for the month
			if (!Object.hasOwn(index.months[month].days[day].day_of_weeks, dayOfWeek)) {
				index.months[month].days[day].day_of_weeks[dayOfWeek] = { intervals: [] };
			}

			const currentIntervals = index.months[month].days[day].day_of_weeks[dayOfWeek].intervals;

			// If intervals[] is empty just add first interval

			if (currentIntervals.length == 0) {
				currentIntervals.push([speedFactor.interval_start, speedFactor.interval_end]);
			} else {
				for (const interval of currentIntervals) {
					if (
						(speedFactor.interval_start >= interval[0] && speedFactor.interval_start < interval[1]) ||
						(speedFactor.interval_end > interval[0] && speedFactor.interval_end <= interval[1]) ||
						(speedFactor.interval_start <= interval[0] && speedFactor.interval_end >= interval[1])
					) {
						// Add only existence (not fake) speedFacrtors to overrides
						if (speedFactor.id) {
							if (!overlaps.some(el => el.id === speedFactor.id)) {
								overlaps.push(speedFactor);
							}
						}
					}
				}

				currentIntervals.push([speedFactor.interval_start, speedFactor.interval_end]);
			}
		}
	}

	return overlaps;
};

const formatFactorForDb = factor => factor / 100 + 1;

// There are no proper validations on the backend. It means that speed factor with invalid days or intervals could be saved and then you'll have very fun time with debugging on FE
// So think about them as kind of backend validations
// Ideally, the user should not see these errors.
// If users sees it, then somewhere we have an error in the code that generates the data to be sent
//
export const validateFormData = (formData: EventFormData) => {
	const applicableDays = formData.applicableDays;

	// Validate applicableDays
	if (applicableDays) {
		if (applicableDays.length == 0) {
			return {
				valid: false,
				message: 'Validation error: select day of week!'
			};
		}

		if (applicableDays.some(d => d > 7 && d < 0)) {
			return {
				valid: false,
				message: 'Validation error: invalid day of week!'
			};
		}
	}
	// Validate intervals
	const intervals = getIntervals(formData);

	const interval = intervals[0];
	if (interval[0] >= interval[1] || interval[0] < 0 || interval[1] > 24) {
		return {
			valid: false,
			message: 'Validation error: invalid hours!'
		};
	}

	return { valid: true, message: '' };
};
