import React, { useEffect, useState } from 'react';

import { Checkbox, TreeSelect } from '@bringg/react-components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { dontLoseFocus, filterOptionByTitle } from 'bringg-web/services/utils';

import styles from './custom-tree-dropdown-select.module.scss';
interface Props {
	selectedIds: number[];
	options: { value: number; children; title: string }[];
	updateCheckbox: (id: number, isSelected: boolean) => void;
	isSelected: (id: number) => boolean;
	getIndeterminate: (id: number) => { indeterminate: boolean; length: number };
	selectAllText: string;
	selectAllTagText: string;
	placeholder: string;
	dataTestId: string;
	labelText: string;
	slotText: string;
}
const CustomTreeDropdownSelect = ({
	selectedIds: selectedIdsProp,
	options,
	updateCheckbox,
	isSelected,
	getIndeterminate,
	selectAllText,
	selectAllTagText,
	placeholder,
	dataTestId,
	labelText,
	slotText
}: Props) => {
	const { t } = useTranslation();
	const [selectedOptions, setSelectedOptions] = useState(new Map());
	const [filteredOptions, setFilteredOptions] = useState(options);
	const [searchValue, setSearchValue] = useState('');
	const [selectedIds, setSelectedIds] = useState<number[]>();

	useEffect(() => {
		const optionsMap = new Map(
			options.map(({ value, title }) => {
				const selected = isSelected(value);
				const { indeterminate, length } = getIndeterminate(value);
				return [
					value,
					{
						value,
						title,
						selected,
						indeterminate,
						length
					}
				];
			})
		);
		setSelectedOptions(optionsMap);
		setSelectedIds(selectedIdsProp);
	}, [selectedIdsProp]);

	const onCheckboxChange = (event, servicePlanId: number) => {
		updateCheckbox(servicePlanId, event.target.checked);
	};

	const getTagRender = ({ label, value }) => {
		return (
			<span className={classNames(styles.tag)} data-test-id={`option-tag-${value}`}>
				<span className={styles.tagCount}>{selectedOptions.get(value).length}</span>
				<span>{label}</span>
			</span>
		);
	};

	const getDropdownRender = () => {
		return (
			<div onMouseDown={dontLoseFocus}>
				{filteredOptions.length ? (
					filteredOptions.map(option => (
						<div
							key={option.value}
							className={classNames(
								'ant-select-tree-treenode',
								'ant-select-tree-treenode-switcher-close',
								{
									'ant-select-tree-treenode-checkbox-checked':
										selectedOptions.get(option.value)?.selected ||
										selectedOptions.get(option.value)?.indeterminate
								}
							)}
						>
							<Checkbox
								onChange={e => onCheckboxChange(e, option.value)}
								indeterminate={selectedOptions.get(option.value)?.indeterminate}
								checked={selectedOptions.get(option.value)?.selected}
								className={styles.customCheckbox}
							>
								<div className={styles.customCheckboxData}>
									<span className="ant-select-tree-node-content-wrapper ant-select-tree-node-content-wrapper-normal">
										{option.title}
									</span>
									<span>
										{selectedOptions.get(option.value)?.length} {slotText}
									</span>
								</div>
							</Checkbox>
						</div>
					))
				) : (
					<span>{t('CUSTOM_TREE_DROPDOWN_SELECT.NO_DATA')}</span>
				)}
			</div>
		);
	};

	const onSearch = (value: string) => {
		setSearchValue(value);
		setFilteredOptions(options.filter(option => filterOptionByTitle(value, option)));
	};

	const onChange = newSelectedIds => {
		setSelectedIds(prevSelectedIds => {
			const newSelectedIdsSet = new Set<number>(newSelectedIds);
			prevSelectedIds.forEach(prevSelectedId => {
				if (!newSelectedIdsSet.has(prevSelectedId)) {
					updateCheckbox(prevSelectedId, false);
				}
			});
			return newSelectedIds;
		});
	};

	return (
		<div className={styles.customDropdownSelect}>
			<span className={styles.label}>{labelText}</span>
			<TreeSelect
				allowClear
				allowSelectAll
				multiple
				selectAllText={selectAllText}
				data-test-id={dataTestId}
				className={styles.treeSelect}
				value={selectedIds}
				treeData={options}
				dropdownRender={getDropdownRender}
				tagRender={getTagRender}
				selectAllTagText={selectAllTagText}
				placeholder={placeholder}
				onSearch={onSearch}
				showSearch
				searchValue={searchValue}
				showCheckedStrategy={'SHOW_PARENT'}
				treeLine={false}
				onChange={onChange}
			/>
		</div>
	);
};
export default CustomTreeDropdownSelect;
