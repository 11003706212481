import React, { memo, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { BringgFontIcons, BringgIcon } from '@bringg/bringg-icons';
import {
	BringgInput,
	BringgTextArea,
	Collapse,
	DatePicker,
	DummyDragAndDrop,
	Form,
	FormInstance,
	FormItem,
	Select
} from '@bringg/react-components';
import type { Moment } from 'moment';
import { noop } from 'lodash';
import type { UploadChangeParam } from 'antd/lib/upload';
import { useDateTimeFormat } from '@bringg-frontend/hooks';

import { UnwrapArray } from '../../../types/common.consts';
import { TEST_IDS } from '../data-test-ids';
import {
	ALLOWED_ATTACHMENTS_MIME_TYPES,
	ROUTE_OPTIMIZATION_FUNCTIONALITY,
	SUPPORT_TICKET_ZINDEX,
	VALID_TICKET_TYPES
} from '../consts';

import styles from './ticket-form.module.scss';

type Rule = UnwrapArray<React.ComponentProps<typeof FormItem>['rules']>;

interface TicketFormItemProps {
	children: JSX.Element;
	name: string;
	label: string;
	required?: boolean;
	tooltip?: JSX.Element;
	rules?: Rule[];
}

// TODO: reuse this component in custom-attr and template features
const TicketFormItem = memo((props: TicketFormItemProps) => {
	const { t } = useTranslation();
	const { children, label, tooltip, name, required, rules } = props;

	const defaultInputRules = useMemo(() => {
		return [{ required: true, message: t('GLOBAL.FIELD_REQUIRED') }];
	}, [t]);

	const tooltipProps = useMemo(() => {
		if (!tooltip) return null;

		return {
			title: tooltip,
			icon: <BringgIcon iconName={BringgFontIcons.Info} />,
			overlayClassName: styles.labelTooltip,
			zIndex: SUPPORT_TICKET_ZINDEX
		};
	}, [tooltip]);

	return (
		<FormItem
			name={name}
			required={required}
			rules={rules ?? (required ? defaultInputRules : null)}
			className={`${styles.formItemLabel}`}
			label={t(label)}
			tooltip={tooltipProps}
		>
			{children}
		</FormItem>
	);
});

const CollapsePanelHeader = memo(({ text }: { text: string }) => {
	return <span className={styles.collapsePanelHeader}>{text}</span>;
});

interface Props {
	form: FormInstance;
	initialValues: Record<string, any>;
	prefilledInputsDisabled?: boolean;
}

export const TicketForm = memo(({ form, initialValues, prefilledInputsDisabled }: Props) => {
	const { t } = useTranslation();
	const dateTimeFormat = useDateTimeFormat();

	const getCollapseIcon = useCallback(
		({ isActive }) =>
			isActive ? (
				<BringgIcon className={styles.collapseIcon} iconName={BringgFontIcons.ChevronUp} />
			) : (
				<BringgIcon className={styles.collapseIcon} iconName={BringgFontIcons.Chevron} />
			),
		[]
	);

	const maxAttachmentSizeRule = useMemo<Rule>(
		() => ({
			async validator(_, value: UploadChangeParam | undefined) {
				if (!value) {
					return Promise.resolve();
				}

				const filesWithInvalidSize = value?.fileList?.filter(file => file.size / 1024 / 1024 > 50);

				if (filesWithInvalidSize?.length) {
					return Promise.reject(
						new Error(
							`${t('GLOBAL.FILES')} [${filesWithInvalidSize.map(file => file.name).join(', ')}] ${t(
								'GLOBAL.FILE_SIZE_HAS_GREATER_THAN'
							)} 50 Mb`
						)
					);
				}

				return Promise.resolve();
			}
		}),
		[]
	);

	const requestTypeOptions = useMemo(() => {
		return [
			{ name: t('NEW_SUBMIT_TICKET.QUESTION'), id: VALID_TICKET_TYPES.question },
			{ name: t('NEW_SUBMIT_TICKET.INCIDENT'), id: VALID_TICKET_TYPES.incident },
			{ name: t('NEW_SUBMIT_TICKET.NEW_REQUEST'), id: VALID_TICKET_TYPES['new request'] }
		];
	}, [t]);

	const priorityLevelOptions = useMemo(() => {
		return [
			{ name: t('NEW_SUBMIT_TICKET.PRIORITY.LOW'), id: 'Low' },
			{ name: t('NEW_SUBMIT_TICKET.PRIORITY.MEDIUM'), id: 'Medium' },
			{ name: t('NEW_SUBMIT_TICKET.PRIORITY.HIGH'), id: 'High' },
			{ name: t('NEW_SUBMIT_TICKET.PRIORITY.URGENT'), id: 'Urgent' }
		];
	}, [t]);

	const affectedFunctionalityOptions = useMemo(() => {
		return [
			{
				name: t('NEW_SUBMIT_TICKET.AFFECTED_FUNCTIONALITY_BRINGG_UI_DRIVER_APP'),
				id: 'Access to Bringg UI/Driver App'
			},
			{ name: t('NEW_SUBMIT_TICKET.AFFECTED_FUNCTIONALITY_TASKS_RUNS'), id: 'Tasks/Runs' },
			{ name: t('NEW_SUBMIT_TICKET.AFFECTED_FUNCTIONALITY_MAPS'), id: 'Maps/Geocoding' },
			{ name: t('NEW_SUBMIT_TICKET.AFFECTED_FUNCTIONALITY_DRIVERS_TEAMS'), id: 'Drivers/Teams' },
			{ name: t('NEW_SUBMIT_TICKET.AFFECTED_FUNCTIONALITY_FLEETS_CARRIERS'), id: 'Fleets/Carriers' },
			{ name: t('NEW_SUBMIT_TICKET.AFFECTED_FUNCTIONALITY_ROUTE_PLANNER'), id: 'Route Planner' },
			{ name: t('NEW_SUBMIT_TICKET.AFFECTED_FUNCTIONALITY_AUTO_DISPATCH'), id: 'Auto Dispatch' },
			{
				name: t('NEW_SUBMIT_TICKET.AFFECTED_FUNCTIONALITY_ROUTE_OPTIMIZATION'),
				id: ROUTE_OPTIMIZATION_FUNCTIONALITY
			},
			{
				name: t('NEW_SUBMIT_TICKET.AFFECTED_FUNCTIONALITY_CONFIGURATION_CHANGES'),
				id: 'Configuration Changes'
			},
			{ name: t('NEW_SUBMIT_TICKET.AFFECTED_FUNCTIONALITY_INTERFACE_BUG'), id: 'Interface Bug/Slowness' },
			{ name: t('NEW_SUBMIT_TICKET.AFFECTED_FUNCTIONALITY_DELIVERY_BLOCKS'), id: 'Delivery Blocks' },
			{ name: t('NEW_SUBMIT_TICKET.AFFECTED_FUNCTIONALITY_REPORTS'), id: 'Reports' },
			{
				name: t('NEW_SUBMIT_TICKET.AFFECTED_FUNCTIONALITY_QUOTES_SLOT_AVAILABILITY'),
				id: 'Quotes/Slot Availability'
			},
			{ name: t('NEW_SUBMIT_TICKET.AFFECTED_FUNCTIONALITY_DELIVERY_SLOTS'), id: 'Delivery Slots' },
			{ name: t('NEW_SUBMIT_TICKET.AFFECTED_FUNCTIONALITY_OTHER_ISSUE'), id: 'Other issue' }
		];
	}, [t]);

	const disabledDate = (current: Moment): boolean => {
		return current.isAfter();
	};

	const emailTooltip = useMemo(
		() => (
			<>
				{t('NEW_SUBMIT_TICKET.EMAIL_TOOLTIP_PART_1')}
				&nbsp;
				<a
					className="color-like-a-link"
					href="https://bringg.my.site.com/supportcenter"
					target="_blank"
					rel="noopener noreferrer"
				>
					{t('NEW_SUBMIT_TICKET.HELP_PORTAL')}
				</a>
				{`. ${t('NEW_SUBMIT_TICKET.EMAIL_TOOLTIP_PART_2')}`}
			</>
		),
		[t]
	);

	return (
		<Form form={form} layout="vertical" initialValues={initialValues}>
			<Collapse
				expandIconPosition="end"
				defaultActiveKey={['mandatory-details', 'additional-details']}
				expandIcon={getCollapseIcon}
			>
				<Collapse.Panel
					key="mandatory-details"
					forceRender
					className={styles.ticketModalCollapsePanel}
					header={<CollapsePanelHeader text={t('NEW_SUBMIT_TICKET.MANDATORY_DETAILS_GROUP')} />}
				>
					<div className={styles.panelContent}>
						<TicketFormItem required name="type" label="NEW_SUBMIT_TICKET.REQUEST_TYPE">
							<Select
								options={requestTypeOptions}
								placeholder={t('NEW_SUBMIT_TICKET.SELECT_PLACEHOLDER')}
								getPopupContainer={trigger => trigger.parentElement}
								disabled={prefilledInputsDisabled}
							/>
						</TicketFormItem>
						<TicketFormItem
							required
							name="priority"
							label="NEW_SUBMIT_TICKET.PRIORITY_LEVEL"
							tooltip={t('NEW_SUBMIT_TICKET.PRIORITY_LEVEL_TOOLTIP')}
						>
							<Select
								options={priorityLevelOptions}
								placeholder={t('NEW_SUBMIT_TICKET.SELECT_PLACEHOLDER')}
								getPopupContainer={trigger => trigger.parentElement}
							/>
						</TicketFormItem>
						<TicketFormItem
							required
							name="userEmail"
							label={'NEW_SUBMIT_TICKET.YOUR_EMAIL'}
							tooltip={emailTooltip}
						>
							<BringgInput
								placeholder={t('NEW_SUBMIT_TICKET.EMAIL')}
								data-test-id={TEST_IDS.userEmailInput}
							/>
						</TicketFormItem>
						<TicketFormItem required name="userPhone" label={'NEW_SUBMIT_TICKET.YOUR_PHONE'}>
							<BringgInput
								placeholder={t('NEW_SUBMIT_TICKET.PHONE')}
								data-test-id={TEST_IDS.userPhoneInput}
							/>
						</TicketFormItem>
						<TicketFormItem
							required
							name="affected_functionality"
							label="NEW_SUBMIT_TICKET.RELATED_FEATURE"
							tooltip={t('NEW_SUBMIT_TICKET.RELATED_FEATURE_TOOLTIP')}
						>
							<Select
								placeholder={t('NEW_SUBMIT_TICKET.SELECT_PLACEHOLDER')}
								getPopupContainer={trigger => trigger.parentElement}
								options={affectedFunctionalityOptions}
								disabled={prefilledInputsDisabled}
							/>
						</TicketFormItem>
						<TicketFormItem required name="title" label="NEW_SUBMIT_TICKET.SUBJECT">
							<BringgInput
								placeholder={t('NEW_SUBMIT_TICKET.SUBJECT_PLACEHOLDER')}
								disabled={prefilledInputsDisabled}
							/>
						</TicketFormItem>
						<TicketFormItem
							required
							name="description"
							label="NEW_SUBMIT_TICKET.MESSAGE"
							tooltip={t('NEW_SUBMIT_TICKET.MESSAGE_TOOLTIP')}
						>
							<BringgTextArea
								placeholder={t('NEW_SUBMIT_TICKET.MESSAGE_PLACEHOLDER')}
								rows={4}
								showCount
								maxLength={1000}
							/>
						</TicketFormItem>
						<TicketFormItem
							required
							name="expected_behavior"
							label="NEW_SUBMIT_TICKET.EXPECTED_BEHAVIOR"
							tooltip={t('NEW_SUBMIT_TICKET.EXPECTED_BEHAVIOR_TOOLTIP')}
						>
							<BringgTextArea
								placeholder={t('NEW_SUBMIT_TICKET.MESSAGE_PLACEHOLDER')}
								rows={4}
								showCount
								maxLength={255}
							/>
						</TicketFormItem>
						<TicketFormItem
							required
							name="date"
							label="NEW_SUBMIT_TICKET.DATE_OF_INCIDENT"
							tooltip={t('NEW_SUBMIT_TICKET.DATE_OF_INCIDENT_TOOLTIP')}
						>
							<DatePicker
								showTime
								format={dateTimeFormat}
								disabledDate={disabledDate}
								popupStyle={{ zIndex: SUPPORT_TICKET_ZINDEX }}
							/>
						</TicketFormItem>
					</div>
				</Collapse.Panel>
				<Collapse.Panel
					key="additional-details"
					forceRender
					className={styles.ticketModalCollapsePanel}
					header={<CollapsePanelHeader text={t('NEW_SUBMIT_TICKET.ADDITIONAL_DETAILS_GROUP')} />}
				>
					<div className={styles.panelContent}>
						<TicketFormItem
							name="task_ids"
							label="NEW_SUBMIT_TICKET.ORDER_IDS"
							tooltip={t('NEW_SUBMIT_TICKET.ORDER_IDS_TOOLTIP')}
						>
							<BringgInput placeholder={t('NEW_SUBMIT_TICKET.IDS_PLACEHOLDER')} />
						</TicketFormItem>
						<TicketFormItem
							name="user_ids"
							label="NEW_SUBMIT_TICKET.USER_OR_TEAM_IDS"
							tooltip={t('NEW_SUBMIT_TICKET.USER_OR_TEAM_IDS_TOOLTIP')}
						>
							<BringgInput placeholder={t('NEW_SUBMIT_TICKET.IDS_PLACEHOLDER')} />
						</TicketFormItem>
						<TicketFormItem
							name="files"
							label="NEW_SUBMIT_TICKET.ATTACHMENTS"
							rules={[maxAttachmentSizeRule]}
						>
							<DummyDragAndDrop
								maxCount={10}
								onFileUploaded={noop}
								accept={ALLOWED_ATTACHMENTS_MIME_TYPES}
							/>
						</TicketFormItem>
					</div>
				</Collapse.Panel>
			</Collapse>
		</Form>
	);
});
