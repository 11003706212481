import { useCallback, useMemo, useState } from 'react';

import { Radio, Space, Notification } from '@bringg/react-components';
import { SpeedFactor } from '@bringg/dashboard-sdk/dist/SpeedFactor/Service/SpeedFactor.service';

import DialogBox from '../../components/dialog-box/dialog-box';
import { ActionType, EventFormData, validateOverlapping } from '../data-utils';
import { useStores } from 'bringg-web/recipes';
import { useSpeedFactorTranslation } from '../translations';
import OverrideDialog from './override-dialog';

type Props = {
	open: boolean;
	onOk: () => void;
	onCancel: () => void;
	event: any;
	speedFactors: SpeedFactor[];
	values?: EventFormData;
	timezone?: string;
};

const UpdateDialog = ({ open, onOk, onCancel, event, values, speedFactors, timezone }: Props) => {
	const { serviceAreasStore } = useStores();
	const [loading, setLoading] = useState(false);
	const [actionType, setActionType] = useState<ActionType>('current');
	const [openOverride, setOpenOverride] = useState(false);

	const translations = useSpeedFactorTranslation();

	const options = useMemo(() => {
		return [
			{ label: translations.updateCurrent, value: 'current' },
			{ label: translations.updateCurrentAndFollowing, value: 'current_and_following' },
			{ label: translations.updateAll, value: 'all' }
		];
	}, [translations]);

	const handleActionChange = e => {
		setActionType(e.target.value);
	};

	const handleOk = useCallback(
		async (override = false) => {
			setLoading(true);

			if (!override) {
				const result = validateOverlapping(values, speedFactors, event, actionType);

				if (!result.valid) {
					setOpenOverride(true);
					setLoading(false);

					return;
				}
			}

			try {
				const response = await serviceAreasStore.updateSpeedFactor(
					values,
					event,
					actionType,
					speedFactors,
					override,
					timezone
				);

				Notification.success(response.message);

				onOk();
			} catch (error) {
				const message = error instanceof Error ? error.message : String(error);

				Notification.error(message);
			} finally {
				setLoading(false);
			}
		},
		[event, values, actionType]
	);

	const closeOverride = () => {
		setOpenOverride(false);
	};

	return (
		<DialogBox
			title={translations.updateTitle}
			subtitle={translations.updateSubtitle}
			open={open}
			onOk={() => handleOk(false)}
			onCancel={onCancel}
			okText={translations.updateOk}
			okButtonProps={{ disabled: loading }}
			destroyOnClose
		>
			<Radio.Group value={actionType} onChange={handleActionChange}>
				<Space direction="vertical" size="small">
					{options.map(option => (
						<Radio value={option.value} style={{ fontWeight: 400 }} key={option.value}>
							{option.label}
						</Radio>
					))}
				</Space>
			</Radio.Group>

			<OverrideDialog
				open={openOverride}
				onOk={() => handleOk(true)}
				onCancel={closeOverride}
				loading={loading}
			/>
		</DialogBox>
	);
};

export default UpdateDialog;
