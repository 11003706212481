import { getRootEnv } from '@bringg-frontend/bringg-web-infra';
import { GetAvailableManagedAttributeHTTPResponse } from '@bringg/common-utils';
import _ from 'lodash';
import i18next from 'i18next';

function getStore() {
	return getRootEnv().dashboardSdk.sdk.v2.taskManagedAttributes;
}

function getAll() {
	return getStore().getAll();
}

async function fetchAttributes() {
	return await getStore().getAvailableAttributes();
}

async function loadAttributeForTask(taskId: number) {
	await getStore().loadByIds([taskId], { useCache: true });
}

function getAttributeByName<T>(taskId: number, name: string): T | undefined {
	return getStore().get(taskId)?.[name] as T | undefined;
}

function getNameForAttribute(attribute: GetAvailableManagedAttributeHTTPResponse['attributes']['task'][number]) {
	if (attribute.display_name) {
		return attribute.display_name;
	}

	const identifierKey = _.snakeCase(attribute.identifier).toUpperCase();

	return i18next.t(`MANAGED_ATTRIBUTES.IDENTIFIER.${identifierKey}.TITLE`);
}

async function fetchAvailableAttributes(tasksIds: number[]) {
	const availableAttributes = await getStore().fetchAvailableAttributes(tasksIds);
	return availableAttributes;
}

export type ManagedAttributeMetadata = GetAvailableManagedAttributeHTTPResponse['attributes']['task'][number];

export const ManagedAttributesService = {
	getAll,
	loadAttributeForTask,
	getAttributeByName,
	fetchAttributes,
	getNameForAttribute,
	fetchAvailableAttributes
};
